const TOKEN = "access token";

export default class LoanLocalStorageService {
	constructor() {
		if(process.env.VUE_APP_LOAN_TOKEN && !localStorage.getItem(TOKEN))
			this.token = process.env.VUE_APP_LOAN_TOKEN;
	}

	get token() {
		return localStorage.getItem(TOKEN);
	}

	set token(value) {
		localStorage.setItem(TOKEN, value);
	}
}
