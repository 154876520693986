import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { i18n } from "@/plugins/index";
import { getHeaders } from "@/api/config/headers";
import { processApiPayload } from "@/utils/api";
import ServerUnavailableException from "@/exceptions/serverUnavailableException";
import OfflineException from "@/exceptions/offlineException";
import RequestCancelledException from "@/exceptions/requestCancelledException";
import AbortService from "@/services/abortService";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import BadRequestException from "@/exceptions/badRequestException";
import InvalidBusinessOperationException from "@/exceptions/invalidBusinessOperationException";
import httpService from "@/api/client/httpService";
import axios from "axios";

const classifyError = (error: any, { url }: { url: string }) => {
	if(axios.isCancel(error)) {
		return new RequestCancelledException();
	}

	if(error.response) {
		const data: any = error.response.data;

		if(error.response.status === 400)
			return new BadRequestException(data.title, data.detail);

		if(error.response.status === 409)
			return new InvalidBusinessOperationException(data.title, data.detail);

		if(error.response.status === 404)
			return new HttpNotFoundException(data.detail);

		if(error.response.status === 403)
			return new AccessForbiddenException(error.response.config.url);

		console.error(i18n.t("logs.error.requestError"), error.response.data);
		return error;
	} else {
		if(navigator.onLine) {
			return new ServerUnavailableException(url);
		} else {
			return new OfflineException(url);
		}
	}
};

export class Client {
	abortService: AbortService;

	constructor(abortService: AbortService) {
		this.abortService = abortService;
	}

	async get<T>(url: string, options?: object, detailed: boolean = false): Promise<T> {
		try {
			const dateBeforeRequest = Date.now();
			console.log(i18n.t("logs.info.request", { url }));

			let { data, headers: responseHeaders } = await httpService.get(`${url}`, {
				headers: getHeaders(),
				...options,
				signal: this.abortService.getSignal()
			});

			console.log(i18n.t("logs.info.response", { url }), `time: ${Date.now() - dateBeforeRequest}ms`, data);

			if(detailed)
				//@ts-ignore
				return { data, responseHeaders };

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}

	async put<T>(url: string, payload: any, config?: any): Promise<T> {
		if(payload)
			processApiPayload(payload);

		try {
			console.log(i18n.t("logs.info.request", { url }), payload);

			let { data } = await httpService.put(url, payload, {
				headers: getHeaders(),
				...config,
				signal: this.abortService.getSignal()
			});

			console.log(i18n.t("logs.info.response", { url }), data);

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}

	async post<T>(url: string, payload: any, config?: object, detailed: boolean = false): Promise<T> {
		if(payload)
			processApiPayload(payload);

		try {
			console.log(i18n.t("logs.info.request", { url }), payload);

			let { data, headers } = await httpService.post(url, payload, {
				...config,
				signal: this.abortService.getSignal()
			});

			console.log(i18n.t("logs.info.response", { url }), data);

			if(detailed)
				//@ts-ignore
				return { data, headers };

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}

	async patch<T>(url: string, payload: any, config?: object): Promise<T> {
		if(payload)
			processApiPayload(payload);

		try {
			console.log(i18n.t("logs.info.request", { url }), payload);

			let { data } = await httpService.patch(url, payload, {
				...config,
				signal: this.abortService.getSignal()
			});

			console.log(i18n.t("logs.info.response", { url }), data);

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}

	async delete(url: string): Promise<any> {
		try {
			console.log(i18n.t("logs.info.request", { url }));

			let { data } = await httpService.delete(url, {
				headers: getHeaders(),
				signal: this.abortService.getSignal()
			});

			console.log(i18n.t("logs.info.response", { url }), data);

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}
}
