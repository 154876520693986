import InvalidArgumentException from "@/exceptions/invalidArgumentException";
import { mutationTypes } from "@/store/modules/user/types";
import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import AbortService from "@/services/abortService";
import { ActionTree, MutationTree } from "vuex";
import IPageState from "@/store/shared/base/types/pageState";

export default class BaseMixinBuilder {
	abortService: AbortService;

	constructor(abortService: AbortService)
	{
		if(!abortService) throw new InvalidArgumentException("abortService", abortService);

		this.abortService = abortService;
	}

	build() {
		const abortService = this.abortService;
		return {
			actions: <ActionTree<IPageState, any>>{
				[baseMixinTypes.actionTypes.initializeBase]({ dispatch, commit }) {
					commit(mutationTypes.BEFORE_INITIALIZED);
					abortService.initialize();
				},
				[baseMixinTypes.actionTypes.destroyBase]({ dispatch, commit }) {
					abortService.abort();
					dispatch(stateManipulationMixinTypes.actionTypes.resetState);
					commit(baseMixinTypes.mutationTypes.DESTROYED);
				},
				[baseMixinTypes.actionTypes.initialize]({ dispatch }) {
					dispatch(baseMixinTypes.actionTypes.initializeBase);
				},
				[baseMixinTypes.actionTypes.destroy]({ dispatch }) {
					dispatch(baseMixinTypes.actionTypes.destroyBase);
				}
			},
			mutations: <MutationTree<IPageState>>{
				[baseMixinTypes.mutationTypes.DESTROYED](state) {
				},
				[baseMixinTypes.mutationTypes.BEFORE_INITIALIZED](state) {
				},
				[baseMixinTypes.mutationTypes.SET_IS_INITIALIZED](state, value) {
					state.isInitialized = value;
				}
			}
		};
	}
}
