import User from "@/store/modules/user/types/user";

export default class UserState {
	user: User;
	isInitialized: boolean;

	constructor(user: User = new User(), isInitialized: boolean = false) {
		this.user = user;
		this.isInitialized = isInitialized;
	}
}
