import AlertModel from "@/store/modules/alerts/models/alertModel";

export default class AlertsStateModel {
	errorAlerts: AlertModel[];
	infoAlerts: AlertModel[];

	constructor({ errorAlerts, infoAlerts }: { errorAlerts: AlertModel[], infoAlerts: AlertModel[] }) {
		this.errorAlerts = errorAlerts;
		this.infoAlerts = infoAlerts;
	}
}
